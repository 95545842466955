import { forEach } from 'lodash';

let $targets;
let $button;
const IS_DISABLED = 'is-disabled';

export default class CheckBox {
  static init() {
    const $parent = document.querySelector('[data-page-id="chromeinstall"]');
    if (!$parent) return;
    $targets = document.querySelectorAll('[data-js="checkbox"]');
    $button = document.querySelector('[data-js-checkbox="button"]');
    forEach($targets, $target => {
      if (!$target) return;
      $target.addEventListener('change', () => {
        CheckBox.onClick();
      });
    });
  }
  static onClick() {
    const checkList = [];
    forEach($targets, $target => {
      checkList.push($target.checked);
    })
    if (!checkList.includes(false)) {
      $button.classList.remove(IS_DISABLED);
      $button.disabled = false;
    } else {
      $button.classList.add(IS_DISABLED);
      $button.disabled = true;
    }
  }
}
