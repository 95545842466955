const buildList = require('~/js/modules/faq/buildList').default;
import axios from 'axios';

export default async function(apiURL) {

  let listItems;
  let url = `${apiURL}/helps`;
  await axios.get(
    url
  ).then((response) => {
    listItems = response.data;
  })
  .catch((error) => {
    console.log("api error. Please try again later.");
  });

  buildList(listItems);
};
