export default class BorderWidth {
  static init($parents) {
    $parents.forEach($parent => {
      const $title = $parent.querySelector('.contentsbox-title');
      if (!$title) return;
      const $borderTop = $parent.querySelector('.contentsbox-border-top');
      const contentsWidth = $parent.clientWidth;
      const titleWidth = $title.clientWidth;
      const triangleWidth = 39;
      $borderTop.style.width = `${contentsWidth - titleWidth - triangleWidth}px`;
    })
  }
}
