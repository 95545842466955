const Vue = require('vue/dist/vue.min');

export default function(items) {
  return new Vue({
    el: '#vue-faq-list',
    data: {
      items: items,
    },
  });
}
