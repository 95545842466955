import { forEach } from 'lodash';
import { easeInOutSine } from '~/js/js-util/Easing';

const DEFAULT_DURATION = 300;
let _offset = 120, _isStop = false, _frame;

export default class AnchorScroll {
  static init() {
    forEach(document.querySelectorAll('a[href^="#"]'), $target => {
      $target.addEventListener('click', event => AnchorScroll._onClick(event, $target));
    });
  }

  static setOffset(value) {
    _offset = value;
  }

  static scrollTo($goal, duration = DEFAULT_DURATION, offset = 0) {
    return new Promise(resolve => {
      _isStop = false;
      const startY = document.documentElement.scrollTop || document.body.scrollTop;
      const goalY = window.pageYOffset + $goal.getBoundingClientRect().top - offset;
      const startTime = performance.now();
      const loop = currentTime => {
        const progress = (currentTime - startTime) / duration;
        const isComplete = progress >= 1;
        const to = isComplete ? goalY : startY + ((goalY - startY) * easeInOutSine(progress));
        window.scrollTo(0, to);

        if (_isStop) {
          _isStop = false;
          resolve();
          return;
        }
        if (!isComplete) _frame = window.requestAnimationFrame(loop);
        if (isComplete) resolve();
      }
      _frame = window.requestAnimationFrame(loop);
    });
  }

  static stop() {
    _isStop = true;
    if (!_frame) return;
    window.cancelAnimationFrame(_frame);
  }

  static _onClick(event, $target) {
    const id = $target.getAttribute('href').replace('#', '');
    const $goal = document.getElementById(id);
    if (!$goal) return;
    event.preventDefault();
    event.stopPropagation();
    AnchorScroll.scrollTo($goal, DEFAULT_DURATION, _offset);
  }
}
