const buildNews = require('~/js/modules/index/buildNews').default;
import axios from 'axios';

export default async function(apiURL) {
  let newsItems;
  let url = `${apiURL}/notifications?kind=site`;
  await axios.get(
    url
  ).then((response) => {
    newsItems = response.data;
  })
  .catch((error) => {
    console.log("api error. Please try again later.");
  });

  buildNews(newsItems);
};
