export default class ScrollEvent {
  constructor($parent) {
    this.$parent = $parent;
    this.windowH = window.innerHeight;
  }

  scroll() {
    const attrData = this.$parent.getAttribute('data-scroll');
    const position = this.$parent.getBoundingClientRect().top + window.pageYOffset;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    if (attrData && attrData === 'fixed') this._toggleStatus(scroll);

    const isCommonParts = this.$parent.classList.contains('js-common-parts')

    if (isCommonParts) {
      if (scroll > 100) {
        this.$parent.classList.add('is-active');
      } else {
        this.$parent.classList.remove('is-active');
      }
    } else {
      const isShow = scroll > position - this.windowH * 0.65;
      const isActive = this.$parent.classList.contains('is-active');
      if (!isActive && isShow && !attrData) this.$parent.classList.add('is-active');
    }
  }

  _toggleStatus(scroll) {
    const $target = document.querySelector('.js-scroll-target');
    if (!$target) return;

    if (scroll > this.windowH * 0.5) {
      this.$parent.classList.add('is-active');
    } else {
      this.$parent.classList.remove('is-active');
    }

    if ($target.getBoundingClientRect().top < this.windowH) {
      this.$parent.classList.add('is-bottom');
    } else {
      this.$parent.classList.remove('is-bottom');
    }
  }
}
