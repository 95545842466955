const buildSlideshow = require('~/js/modules/manual/buildSlideshow').default;

export default function() {

  const slideshowItems = [
    {
      title: 'ホームタチコマ',
      text: 'ホーム画面にタチコマが登場します。長押しするとアプリを起動。（光学迷彩機能により、タチコマを非表示にすることも可能です。）'
    },
    {
      title: '訓練プログラム',
      text: 'タチコマとともにコースを駆け抜け、先輩課員から提示されるタスク（クイズ）を解決しましょう。優秀な働きをすると、報酬としてメダルやポイントが獲得できることがあります。'
    },
    {
      title: 'プロファイル',
      text: '自分やみんなのスマートフォンの利用状況を確認できます。健康的なデジタルライフに役立ててください。'
    },
    {
      title: 'タチコマへ報告',
      text: '不審なサイトやスパムメールを見つけたら、ぜひ報告してください。スタンダード君が手順を案内します。有用な情報を報告すると、ポイントを獲得できます。'
    },
    {
      title: 'コレクション',
      text: 'たくさん活動してポイントを獲得するとマテリアライズ機能によって報酬に変換することができます。イラストカードやボイス等の報酬が手に入ります。手に入れた報酬は、コレクション画面で確認できます。\n※マテリアライズは今後実装予定'
    },
    {
      title: 'ネットワークスキャン',
      text: 'ご使用のスマホがご家庭のWiFi環境に接続されていることを確認した上で実行します。同じネットワークに接続された機器（IoTなど）に、意図していないサービスが起動していたり、攻撃に遭うもしくは悪用されるリスクの高い機器がないかチェックできます。'
    },
    {
      title: 'セキュリティアラート',
      text: '疑わしいサイトへのアクセスや疑わしいサイトへたどり着く可能性が高いと推測される検索キーワードの入力、疑わしいアプリのインストールを発見したらタチコマが教えてくれます。'
    }
  ];
  buildSlideshow(slideshowItems);
};
