const buildNewsDetail = require('~/js/modules/news/buildNewsDetail').default;
import axios from 'axios';

export default async function(apiURL) {

  if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const newsId = urlParams.get('id');

    let newsData;
    let url = `${apiURL}/notifications/` + newsId + '/';
    await axios.get(
      url
    ).then((response) => {
      newsData = response.data;
    })
    .catch((error) => {
      console.log("api error. Please try again later.");
    });

    buildNewsDetail(newsData);

  }
};
