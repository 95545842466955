const Vue = require('vue/dist/vue.min');

export default function(newsData) {
  return new Vue({
    el: '#vue-news',
    data: {
      newsData: newsData,
    },
    mounted: function() {
      const title = this.newsData.title + ' | お知らせ | タチコマ・セキュリティ・エージェント・モバイル';
      document.title = title;

      const href = location.href;
      const meta = document.head.children;
      for (var i = 0; i < meta.length; i++) {
        const proper = meta[i].getAttribute('property');
        const name = meta[i].getAttribute('name');
      	if( proper === 'og:url' ){
      		meta[i].setAttribute('content', href);
      	} else if( proper === 'og:title' ) {
          meta[i].setAttribute('content', title);
        } else if ( name === 'twitter:title' ) {
          meta[i].setAttribute('content', title);
        }
      }
    },
    methods: {
      formatDate(unixtime) {
        let dateTime = new Date(unixtime * 1000);
        return dateTime.toLocaleDateString();
      },
      formatText(newsData) {
        let textData = newsData.replace(/\r?\n/g, '<br>');
        let linkExp = new RegExp(/(https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let brExp = new RegExp(/(<br>)/);
        let re = new RegExp(linkExp.source+/|/.source+brExp.source, linkExp.flags);
        let chunks = textData.split(re);
        let text = "";
        for (let i = 0; i < chunks.length; i++){
          if (chunks[i]){
            if (chunks[i].indexOf("http") === 0) {
              const tmp = chunks[i];
              chunks[i] = '<a href="'+ tmp + '">' + tmp + '</a>'
            }
            text += chunks[i];
          }
        }
        return text;
      }
    },
  });
}
