import Hover from '~/js/js-util/Hover';
const buildGlobalMenu = require('~/js/modules/common/buildGlobalMenu').default;

export default function() {
  const elmHover = document.querySelectorAll('.js-hover');
    for (var i = 0; i < elmHover.length; i++) {
      new Hover(elmHover[i]);
    }
  buildGlobalMenu();
};
