import Hover from '~/js/js-util/Hover';
const Vue = require('vue/dist/vue.min');
const path = '/mobile-app';

export default function(items) {
  return new Vue({
    el: '#vue-index-news',
    data: {
      items: items,
    },
    mounted: function() {
      const elmHover = document.querySelectorAll('.js-hover');
        for (var i = 0; i < elmHover.length; i++) {
          new Hover(elmHover[i]);
        }
    },
    methods: {
      setHref(id) {
        return path + '/news/?id=' + id;
      },
      formatDate(unixtime) {
        let dateTime = new Date(unixtime * 1000);
        return dateTime.toLocaleDateString();
      },
    }
  });
}
