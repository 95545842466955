import { forEach } from 'lodash';

export default class Accordion {
  static init() {
    const $parents = document.querySelectorAll('[data-js="accordion"]');

    forEach($parents, $target => {
      const $trigger = $target.querySelector('[data-js-accordion="trigger"]');
      const $frame = $target.querySelector('[data-js-accordion="frame"]');
      const $content = $target.querySelector('[data-js-accordion="content"]');

      $trigger.addEventListener('click', () => {
        if ($target.classList.contains('is-active')) {
          $frame.style.height = 0;
          $target.classList.remove('is-active');
        }else {
          $target.classList.add('is-active');
          $frame.style.height = `${$content.clientHeight}px`;
        }
      });
    });
  }
}
