import Hover from '~/js/js-util/Hover';
const Vue = require('vue/dist/vue.min');

export default function(items) {
  return new Vue({
    el: '#vue-slideshow',
    data: {
      mounted: false,
      items: items,
      currentNum: 0,
      prevNum: items.length - 1,
      nextNum: 1,
      touchX: 0,
      moveX: 0,
      isAnimatePrev: false,
      isAnimateNext: false,
      isClick: false,
      isDrag: false,
      showCount: items.length
    },
    mounted: function() {
      const elmHover = document.querySelectorAll('.js-hover');
        for (var i = 0; i < elmHover.length; i++) {
          new Hover(elmHover[i]);
        }
      this.mounted = true;
    },
    computed: {
      getTitle: function() {
        return this.items[this.currentNum].title;
      },
      getText: function() {
        return this.items[this.currentNum].text;
      },
      getMoveX: function() {
        if (this.isDrag) {
          return 'translateX(' + (this.moveX - this.touchX) + 'px)';
        } else {
          return '';
        }
      }
    },
    methods: {
      startDrag: function(event) {
        event.preventDefault();
        if (this.isAnimatePrev || this.isAnimateNext) return;
        if (event.touches) {
          this.touchX = event.touches[0].clientX;
          this.moveX = event.touches[0].clientX;
        } else {
          this.touchX = event.clientX;
          this.moveX = event.clientX;
        }
        this.isClick = true;
        this.isDrag = true;
      },
      drag: function(event) {
        event.preventDefault();
        if (this.isDrag) {
          if (event.touches) {
            this.moveX = event.touches[0].clientX;
          } else {
            this.moveX = event.clientX;
          }
        }
      },
      finishDrag: function(event) {
        event.preventDefault();
        if (this.moveX - this.touchX > 0) {
          this.isAnimatePrev = true;
          this.setCurrentNum();
        } else if (this.moveX - this.touchX < 0) {
          this.isAnimateNext = true;
          this.setCurrentNum();
        }
        this.touchX = 0;
        this.moveX = 0;
        this.isClick = false;
        this.isDrag = false;
      },
      gotoPrev: function() {
        if (this.isAnimatePrev || this.isAnimateNext) return;
        this.isAnimatePrev = true;
        this.setCurrentNum();
      },
      gotoNext: function() {
        if (this.isAnimatePrev || this.isAnimateNext) return;
        this.isAnimateNext = true;
        this.setCurrentNum();
      },
      setCurrentNum: function() {
        if (this.isAnimatePrev) {
          this.currentNum = this.prevNum;
        } else if (this.isAnimateNext) {
          this.currentNum = this.nextNum;
        }
        setTimeout(() => {
          this.isAnimatePrev = false;
          this.isAnimateNext = false;
          this.prevNum = (this.currentNum == 0) ? this.showCount - 1 : this.currentNum - 1;
          this.nextNum = (this.currentNum == this.showCount - 1) ? 0 : this.currentNum + 1;
        }, 200);
      },
      setCurrentNumByPager: function(index) {
        this.currentNum = index;
        setTimeout(() => {
          this.isAnimatePrev = false;
          this.isAnimateNext = false;
          this.prevNum = (this.currentNum == 0) ? this.showCount - 1 : this.currentNum - 1;
          this.nextNum = (this.currentNum == this.showCount - 1) ? 0 : this.currentNum + 1;
        }, 200);
      },
    }
  });
}
