require('@babel/polyfill');
import '../scss/main.scss';
import { UAParser } from 'ua-parser-js';
import sleep from '~/js/js-util/sleep';
import AnchorScroll from '~/js/js-util/AnchorScroll';
import CheckBox from '~/js/js-util/CheckBox';
import Accordion from '~/js/js-util/Accordion';
import BorderWidth from '~/js/js-util/BorderWidth';
import ScrollEvent from '~/js/js-util/ScrollEvent';

const $parent = document.querySelector('.l-page');
const pageId = $parent ? $parent.getAttribute('data-page-id') : '';
const uaParser = new UAParser();
const link = document.querySelector('link[as=style]');

// APIのURL一覧
const apiURL = {
  dev: 'https://dev-api.tachikoma-sa.click/api/v1',
  stg: 'https://stg-mobile-app-api.warpdrive-project.jp/api/v1',
  prod: 'https://mobile-app-api.warpdrive-project.jp/api/v1'
};
// 使用するAPIの環境指定
const isEnvProd = PROCESS_NODE_ENV === 'production';
const isEnvStg = PROCESS_NODE_ENV === 'staging';
const env = isEnvProd ? 'prod' : isEnvStg ? 'stg' : 'dev';

const _scrolls = [];

const init = async () => {
  // Preload the stylesheet in browsers that are enabled an attribute link.preload.
  const browser = uaParser.getBrowser().name;
  if (browser !== 'Chrome' && browser !== 'Edge') link.rel = 'stylesheet';

  await sleep(100);

  // run initialize function.
  require ('./init/common.js').default();
  AnchorScroll.init();
  CheckBox.init();
  Accordion.init();
  
  const $contentsBoxes = document.querySelectorAll('.contentsbox');

  window.addEventListener('resize', () => {
    if ($contentsBoxes.length > 0) BorderWidth.init($contentsBoxes);
  });
  if ($contentsBoxes.length > 0) BorderWidth.init($contentsBoxes);

  window.addEventListener('scroll', _onScroll, { passive: true });
  _onScroll();
  _scrollEvent();

  if (!pageId) return;

  switch (pageId) {
    case 'index':
      require ('./init/index.js').default(apiURL[env]);
      break;
    case 'manual':
      require ('./init/manual.js').default();
      break;
    case 'faq':
      require ('./init/faq.js').default(apiURL[env]);
      break;
    case 'newsIndex':
      require ('./init/news.js').default(apiURL[env]);
      break;
    default:
  }
}
init();

const _onScroll = () => {
  if (_scrolls.length < 1) return;
  _scrolls.forEach((item) => {
    item.scroll();
  });
};

const _scrollEvent = () => {
  const $parents = document.querySelectorAll('.js-scroll');
  $parents.forEach(($parent) => {
    _scrolls.push(new ScrollEvent($parent));
  });
};

document.fonts.ready.then(async (fontFaceSet) => {
  const $parent = document.querySelector('.chrome-all');
  if (!$parent) return;

  await sleep(500);
  $parent.setAttribute('data-status', 'active');
});
