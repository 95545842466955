import Hover from '~/js/js-util/Hover';
import FixBody from '~/js/js-util/FixBody';
const Vue = require('vue/dist/vue.min');

export default function() {
  return new Vue({
    el: '#vue-global-menu',
    data: {
      fixBody: new FixBody(),
      isOpened: false,
      isScroll: false,
    },
    mounted: function() {
      const elmHover = document.querySelectorAll('.js-hover');
        for (var i = 0; i < elmHover.length; i++) {
          new Hover(elmHover[i]);
        }
      window.addEventListener('scroll', ()=>{
        window.pageYOffset > 0 ? this.isScroll = true : this.isScroll = false;
      })
    },
    methods: {
      toggleMenu() {
        this.isOpened = !this.isOpened;
        if (this.isOpened) {
          this.fixBody.set();
        } else {
          this.fixBody.cancel();
        }
      },
    },
  });
}
